import React, { useState } from 'react'
import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import BlueHeader from '../../Shared/UI/BlueHeader'
import Title from '../../Shared/UI/Title'
import Content from '../../Shared/UI/Content'
import Button from '../../Shared/UI/Button'
import { formAccessHandler } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ classes }) => {
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const emailInputRandomId = Math.random()

  return (
    <div className={styles.fullBackground}>
      <Container classes={styles.outer}>
        <Wrapper classes={cx(styles.wrapper, classes)}>
          <Item classes={styles.contentContainer}>
            <div>
              <BlueHeader className={styles.blueHeader}>GET STARTED WITH MAXIMUS NOW</BlueHeader>
              <Title className={styles.title} blueLine={false}>
                Free Access to Our Online Community & E-Book!
              </Title>
            </div>
          </Item>
          <Item classes={styles.contentContainer}>
            {!submit && (
              <form
                onSubmit={(e) => formAccessHandler(e, loading, setLoading, setSubmit)}
                className={cx(styles.salesForm, 'remove-focus', {
                  [styles.loading]: loading,
                })}
              >
                <label for={`community-email-${emailInputRandomId}`} className={styles.inputLabel}>
                  Email
                </label>
                <input
                  required
                  type="email"
                  id={`community-email-${emailInputRandomId}`}
                  name="email"
                  placeholder="Email"
                  className={styles.input}
                ></input>
                <Button button={true} btnType="full" url="#" classes={styles.button}>
                  Submit
                </Button>
              </form>
            )}
            {submit && <Content>We will send you an email shortly. Please check your mailbox.</Content>}
          </Item>
        </Wrapper>
      </Container>
    </div>
  )
}
