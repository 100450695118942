import React from 'react'

import Container from '../../Shared/UI/Container'
import Wrapper from '../../Shared/UI/Wrapper'
import Item from '../../Shared/UI/Item'
import Button from '../../Shared/UI/Button'

import Spotify from '../../Shared/Icons/Podcasts/Spotify'
import Google from '../../Shared/Icons/Podcasts/Google'
import Apple from '../../Shared/Icons/Podcasts/Apple'

import styles from './style.module.sass'

export default ({ podcasts }) => (
  <Container classes={styles.outer}>
    <Wrapper classes={styles.wrapper}>
      <Item classes={styles.contentContainer}>
        <div className={styles.podcastRecord}>
          <Spotify />
          <Button btnType="full" url={podcasts.spotify} classes={styles.button}>
            Listen Now
          </Button>
        </div>
        <div className={styles.podcastRecord}>
          <Google />
          <Button btnType="full" url={podcasts.google} classes={styles.button}>
            Listen Now
          </Button>
        </div>
        <div className={styles.podcastRecord}>
          <Apple />
          <Button btnType="full" url={podcasts.apple} classes={styles.button}>
            Listen Now
          </Button>
        </div>
      </Item>
    </Wrapper>
  </Container>
)
