import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Podcasts from '../components/PodcastsPage/Podcasts'
import PodcastIntro from '../components/PodcastsPage/PodcastIntro'
import LatestPodcasts from '../components/PodcastsPage/LatestPodcasts'
import styles from '../styles/podcast.module.sass'

export default ({ pageContext }) => {
  const { data } = pageContext

  return (
    <Layout data={data} headerClasses={styles.headerStyle} heroBodyClasses={styles.heroBody}>
      <SEO {...data.seo} />
      <PodcastIntro podcastIntro={data.podcastIntro} />
      {data.settings && <Podcasts podcasts={data.settings.podcasts} />}
      <LatestPodcasts latestPodcasts={data.latestPodcasts} />
    </Layout>
  )
}
